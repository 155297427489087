import * as types from './ActionTypes';
import { create } from 'axios';
import { createEventGroupById } from '../../utils';

const API_URL = `${process.env.REACT_APP_BASE_API_URL}/api/v1/event-association/external-events`;

const ajax = create({
  timeout: 100 * 1000,
  contentType: 'application/json'
});

ajax.defaults.withCredentials = true;

export const saveLastParams = (params) => dispatch => {
  dispatch({
    type: types.SAVE_PARAMS,
    payload: params
  });
};

export const getEventsAsync = (params) => (dispatch) => {
  dispatch(saveLastParams(params));

  dispatch({
    type: types.GET_EVENTS_PENDING
  });

  const needsConfirmationParams = { state: 'association suggested', ...params };
  const unassociatedParams = { state: 'pending association', ...params };
  const associatedParams = { state: 'association confirmed', ...params };

  async function getAllEvents() {
    try {
      const needsConfirmationPromise = ajax.get(API_URL, { params: needsConfirmationParams });
      const unassociatedPromise = ajax.get(API_URL, { params: unassociatedParams });
      const associatedPromise = ajax.get(API_URL, { params: associatedParams });
      const [needsConfirmation, unassociated, associated] = await Promise.all([needsConfirmationPromise, unassociatedPromise, associatedPromise]);

      dispatch({
        type: types.GET_EVENTS_SUCCESS,
        payload: {
          needsConfirmation: createEventGroupById(needsConfirmation.data.events, needsConfirmation.data.externalEvents),
          unassociated: unassociated.data,
          associated: createEventGroupById(associated.data.events, associated.data.externalEvents)
        }
      });
    } catch (error) {
      if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        dispatch({
          type: types.GET_EVENTS_ABORTED,
          payload: error
        });
      } else {
        dispatch({
          type: types.GET_EVENTS_REJECTED,
          payload: error
        });
      }
    }
  }

  getAllEvents();
};

export const removeEvent = (associations) => (dispatch, getState) => {
  const params = getState().params.data;
  const state = 'pending association';

  const externalEvents = associations.map(event => {
    return {
      externalEventId: event.id,
      eventId: event.eventId,
      state: state
    };
  });

  associationService(externalEvents, state, params, dispatch);
};

export const removeAssociations = (associations) => dispatch => {
  dispatch(changeAssociations(associations, 'pending association'));
};

export const confirmAssociation = (associations) => dispatch => {
  dispatch(changeAssociations(associations, 'association confirmed'));
};

// Utilities
function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

function associationService(externalEvents, state, params, dispatch) {
  dispatch({
    type: types.CHANGE_ASSOCIATION_PENDING
  });

  ajax.put(`${API_URL}/associate`, { externalEvents })
    .then(resp => {
      dispatch({
        type: types.CHANGE_ASSOCIATION_SUCCESS,
        payload: resp.data
      });

      setTimeout(() => {
        dispatch(getEventsAsync(params));
      }, 1000);

    })

    .catch(error => {
      dispatch({
        type: types.CHANGE_ASSOCIATION_REJECTED,
        payload: error
      });
    });
}

function changeAssociations(associations, state) {
  return (dispatch, getState) => {
    const params = getState().params.data;
    const internalEvents = associations.map((internalEvent) => {

      return internalEvent.externalEvents.map(event => {
        return {
          externalEventId: event.id,
          eventId: internalEvent.id,
          state: state
        };
      });
    });

    const externalEvents = flatten(internalEvents);

    associationService(externalEvents, state, params, dispatch);
  };
}
